<template>
  <div>
    <a-form-model :colon="false" @submit.prevent="$emit('submit')" spellcheck="false" ref="form" :model="form" :rules="rules">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col lg="12">
               <a-form-model-item
                label="Full Name"
                name="full_name"
                prop="full_name">
                <a-input :disabled="loading" required v-model="form.full_name" />
              </a-form-model-item>
            </b-col>
            <b-col lg="12">
              <a-form-model-item
                label="Phone"
                name="phone"
                prop="phone">
                <a-input :disabled="loading" required v-model="form.phone" />
              </a-form-model-item>
            </b-col>
          </b-row>
          <a-divider class="text-muted">Sign-in Information</a-divider>
          <b-row>
            <b-col lg="6">
              <a-form-model-item
                label="Email"
                name="email"
                prop="email">
                <a-input :disabled="loading" required v-model="form.email" />
              </a-form-model-item>
            </b-col>
            <b-col lg="6">
              <a-form-model-item
                label="Username"
                name="username"
                prop="username">
                <a-input :disabled="loading" required v-model="form.username" />
              </a-form-model-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
               <a-form-model-item
                label="Password"
                name="password"
                prop="password">
                <password default-class="ant-input" v-model="form.password" :disabled="loading" :show-password="showPassword" :badge="false" :toggle="true"/>
              </a-form-model-item>
            </b-col>
            <b-col lg="6">
              <a-form-model-item
                label="Re-type Password"
                name="password_confirmation"
                prop="password_confirmation">
                <a-input type="password" :disabled="loading" required v-model="form.password_confirmation" />
              </a-form-model-item>
            </b-col>
          </b-row>
          <a-divider class="text-muted">Access &amp; Permissions</a-divider>
          <b-row>
            <b-col lg="6">
              <a-form-model-item
                label="Portals"
                name="portals"
                prop="portals">
                <a-select
                  mode="multiple"
                  :disabled="loading && portalDataLoading"
                  allow-clear
                  :options="user.portals.map((portal) => {
                    return {
                      ...portal,
                      value: portal.id,
                      label: `${portal.title} (${portal.domain})`,
                    }
                  })"
                  v-model="form.portals"
                  tree-default-expand-all
                  :loading="loading && portalDataLoading"
                  placeholder="Select Portals" />
              </a-form-model-item>
            </b-col>
            <b-col lg="6">
              <a-form-model-item
                label="Roles"
                name="roles"
                prop="roles">
                <a-select
                  mode="multiple"
                  :disabled="loading && roleDataLoading"
                  allow-clear
                  :options="roles"
                  v-model="form.roles"
                  tree-default-expand-all
                  :loading="loading && roleDataLoading"
                  placeholder="Select Roles" />
              </a-form-model-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <a-form-model-item
                label="Status"
                name="status"
                prop="status">
                <a-select
                  :disabled="loading"
                  :options="[
                  {
                    value: 1,
                    label: 'Active',
                  },
                  {
                    value: 0,
                    label: 'Inactive',
                  }
                  ]"
                  v-model="form.active"
                  :loading="loading"
                  placeholder="Select Status" />
              </a-form-model-item>
            </b-col>
          </b-row>
          <!-- <a-form-model-item
            :disabled="loading"
            label="Image"
            name="image_id"
            prop="image_id">
            <div class="media__preview mt-2" v-if="Object.keys(form.image_media).length">
              <button @click.prevent="removeMedia" class="media__remove"><i class="fe fe-x"></i></button>
              <img :src="`${form.image_media.url}?tr=w-322`"
                :srcset="`${form.image_media.url}?tr=w-322 1x, ${form.image_media.url}?tr=w-644 2x`"
                :alt="form.image_media.alt" />
            </div>
            <b-button class="mt-2" variant="outline-info" @click.prevent="mediaFinderOpen">{{ Object.keys(form.image_media).length ? 'Change Image' : 'Add Image' }}</b-button>
          </a-form-model-item> -->
          <a-divider></a-divider>
          <div class="text-right">
            <b-button :disabled="loading" variant="primary" @click="submit()">
              {{ type === 'create' ? 'Add New User' : 'Save Changes' }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </a-form-model>

    <!-- Media Finder for Featured/Hero Image -->
    <b-modal hide-footer id="media-finder" size="xl" body-class="p-0 bg-light" centered title="Add Media">
      <MediaFinder :current="[form.image_media_id]" v-on:insert="applyMedia" />
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MediaFinder from '../media/finder'
import Password from 'vue-password-strength-meter'

export default {
  name: 'mainForm',
  props: {
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      showPassword: false,
      rules: {
        title: [
          {
            required: true,
            message: 'Please insert title',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input your email address',
          },
          {
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: 'Wrong email format',
          },
        ],
        username: [
          {
            required: true,
            message: 'Please input your username',
          },
          {
            min: 5,
            message: 'Please input at least 5 characters',
          },
          {
            whitespace: true,
          },
          {
            validator: this.usernameValidator,
            message: 'Wrong username format',
          },
        ],
        password: [
          {
            required: this.type === 'create',
            message: 'Please input password',
          },
        ],
        password_confirmation: [
          {
            required: this.type === 'create',
            message: 'Please input password confirmation',
          },
          {
            validator: this.passwordValidator,
            message: 'Password didn\'t match',
          },
        ],
      },
    }
  },
  created() {
    const vm = this
    // vm.$store.dispatch('portal/FETCH')
    vm.$store.dispatch('role/FETCH')

    vm.form.portals.push(vm.settings.activePortal)
  },
  computed: {
    ...mapState(['settings', 'user']),
    // ...mapState('portal', {
    //   portals: state => state.data,
    // }),
    ...mapState('role', {
      roles: state => state.data,
    }),
    ...mapState('user', {
      form: state => state.form,
    }),
    loading() {
      return this.$store.state.user.loading
    },
    portalDataLoading() {
      return this.$store.state.portal.loading
    },
    roleDataLoading() {
      return this.$store.state.role.loading
    },
  },
  methods: {
    submit() {
      const vm = this

      vm.$set(vm.form, 'categories', vm.category_ids)
      vm.$refs.form.validate(valid => {
        if (valid) {
          vm.$emit('submit')
        } else {
          return false
        }
      })
    },
    passwordValidator: function(rule, value, callback) {
      return value === this.form.password
    },
    usernameValidator: function(rule, value, callback) {
      const pattern = /^[a-z0-9]+$/i
      return pattern.test(value)
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    // Media
    removeMedia() {
      const vm = this
      vm.$set(vm.form, 'image_media_id', '')
      vm.$set(vm.form, 'image_media', {})
    },
    mediaFinderOpen() {
      const vm = this

      vm.$bvModal.show('media-finder')
    },
    applyMedia(media, medias) {
      // console.log(media, medias)
      const vm = this
      vm.$set(vm.form, 'image_media', media)
      vm.$set(vm.form, 'image_media_id', media.id)
      vm.$bvModal.hide('media-finder')
    },
  },
  components: {
    MediaFinder,
    Password,
  },
  destroyed() {
    this.$store.commit('user/CLEAR_DATA')
    this.$store.commit('user/CLEAR_FORM')
  },
}
</script>

<style lang="scss">
@import "./style.module.scss";
</style>
